<script>
/**
 * Transactions component
 */
import { getPurchaseRequests } from "@/services/land.service";
// import AddPayment from '@/views/pages/lands/components/add-payment';
import PaymentHistory from '@/views/pages/lands/payment-history';
import AccessControl from '@/views/access-control';
import AgentDetailsModal from "@/views/agent-modal";
import CustomerDetailsModal from "@/views/customer-modal";

export default {
  components: {
    // AddPayment,
    PaymentHistory,
    AccessControl,
    AgentDetailsModal,
    CustomerDetailsModal
  },
  data() {
    return {
      selectedUser: {},
      selectedPurchase: {},
      paymentHistory: [],
      fetchingPurchases: false,
      purchases: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 25, 50, 100],
      filter: null,
      isBusy: false,
      filterOn: [],
      sortBy: "code",
      sortDesc: true,
      fields: [
        '#',
        {
          key: "code",
          label: "ID",
        },
        {
          key: "created_at",
          formatter: (value) => {
            return this.$options.filters.dateFormat(value);
          },
        },
        {
          key: "estate",
          formatter: (value) => {
            return value.name;
          },
        },
        'no_of_plots',
        {
          key: "customer",
          formatter: (value) => {
            return value.firstname + " " + value.lastname;
          },
        },
        {
          key: "agent",
          label: "Referred By",
          formatter: (value) => {
            return value.username == this.username ? "@Me" : value.username;
          },
        },
        {
          key: "amount_paid",
          sortable: true,
          formatter: (value) => {
            return this.$options.filters.currencyFormat(value);
          },
        },
        'commission',
        // {
        //   key: "commission",
        //   label: "Commission %",
        //   formatter: (value) => {
        //     return value ? value.rate : "NA";
        //   },
        // },
        // {
        //   key: "commission",
        //   label: "Commission Amount",
        //   formatter: (value) => {
        //     const amount = value ? value.amount : 0;
        //     return this.$options.filters.currencyFormat(amount);
        //   },
        // },
        {
          key: "payment_status",
          sortable: true,
        },
        "actions",
      ],
    };
  },
  mounted: async function () {
    try {
      this.isBusy = true;
      let response = await getPurchaseRequests();
      this.purchases = response.data.data;
      console.log('Here is here')
      console.log(this.purchases)
      this.totalRows = this.purchases.length;
      this.isBusy = false;
      console.log(response);
    } catch (error) {
      this.isBusy = false;
      alert(error);
      console.log(error);
    }
  },
  computed: {
    username() {
      return this.$store.state.auth.user
        ? this.$store.state.auth.user.username
        : null;
    },
  },
  methods: {
    makePayment(data) {
      this.selectedPurchase = data.item
      this.paymentHistory = data.item.payments
      this.$refs['purchase-modal'].show();
    },
    addToPaymentHistory(payment, paymentPlan) {
      var index = this.purchases.findIndex((purchase => purchase.id == this.selectedPurchase.id));
      this.purchases[index].payments.push(payment)
      // update payment status and total amount_paid
      this.purchases[index].amount_paid += parseFloat(payment.amount)
      this.purchases[index].payment_status = payment.status
      this.purchases[index].plan = paymentPlan
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Land Sales</h4>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
          :items="purchases"
          :fields="fields"
          responsive="sm"
          :busy="isBusy"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          show
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> &nbsp; Loading...</strong>
            </div>
          </template>
          <template #cell(#)="data">
              {{ data.index + 1 }}
            </template>

            <template v-slot:cell(code)="data">
                <a
                  href="javascript:void(0);"
                  class="mr-3 text-success"
                  v-b-tooltip.hover
                  @click="
                    selectedUser = data.item.customer;
                    $refs['customer-details-modal'].show();
                  "
                >
                  {{data.item.code }}
                </a>
            </template>
            <template v-slot:cell(customer)="data">
                <a
                  href="javascript:void(0);"
                  class="mr-3 text-success"
                  v-b-tooltip.hover
                  @click="
                    selectedUser = data.item.customer;
                    $refs['customer-details-modal'].show();
                  "
                >
                  {{data.item.customer.firstname}} {{ data.item.customer.lastname}}
                </a>
            </template>
          <template v-slot:cell(commission)="data">
            <div v-if="data.item.commission">
              {{ data.item.commission.amount | currencyFormat }} 
              <span
                class="badge font-size-12"
                :class="{
                  'badge-warning': `${data.item.commission.status}` === 'pending',
                  'badge-success': `${data.item.commission.status}` === 'completed',
                }"
              >
                {{ data.item.commission.rate }} %
              </span>
            </div>
            <div v-else>
              NA
            </div>
          </template>
          <template v-slot:cell(payment_status)="row">
            <div
              class="badge font-size-12"
              :class="{
                'badge-soft-danger': `${row.value}` === 'pending',
                'badge-soft-success': `${row.value}` === 'completed',
                'badge-soft-warning': `${row.value}` === 'installment',
              }"
            >
              {{ row.value }}
            </div>
          </template>

            <template v-slot:cell(agent)="data" v-if="$store.state.auth.user.iam != 'agent'">
              <AccessControl permission="manage purchases">
                  <a
                    href="javascript:void(0);"
                    class="mr-3 text-success"
                    v-b-tooltip.hover
                    @click="
                      selectedUser = data.item.agent;
                      $refs['agent-details-modal'].show();
                    "
                  >
                    {{data.item.agent.username == username ? "@Me" : data.item.agent.username }}
                  </a>
              </AccessControl>
            </template>

          <template v-slot:cell(actions)="data">
            <AccessControl permission="manage purchases">
                <a
                href="javascript:void(0);"
                class="mr-3 text-success"
                v-b-tooltip.hover
                data-toggle="tooltip"
                title="Payments"
                @click="makePayment(data)"
              >
                <i class="mdi mdi-wallet-plus font-size-18 font-weight-bold"></i>
              </a>
            </AccessControl>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="agent-details-modal"
      size="md"
      centered
      scrollable
      title="User Info"
      title-class="font-18"
      hide-footer
    >
      <AgentDetailsModal :agent="selectedUser"/>
    </b-modal>

    <b-modal
      ref="customer-details-modal"
      size="md"
      centered
      scrollable
      title="Client Info"
      title-class="font-18"
      hide-footer
    >
      <CustomerDetailsModal :customer="selectedUser"/>
    </b-modal>

    <b-modal
      ref="purchase-modal"
      size="lg"
      centered
      scrollable
      :title="selectedPurchase.customer ? selectedPurchase.customer.firstname + ' ' + selectedPurchase.customer.lastname + ' Payments (' + selectedPurchase.code + ')' : ''"
      title-class="font-18"
      hide-footer
    >
    <div class="text-center text-primary my-2" v-if="fetchingPurchases">
          <b-spinner class="align-middle"></b-spinner>
          <strong> &nbsp; Loading...</strong>
        </div>
    <div v-else>
      <PaymentHistory @addToPaymentHistory="addToPaymentHistory" :paymentHistory="paymentHistory" :selectedPurchase="selectedPurchase"/>
    </div>
    </b-modal>
  </div>
</template>