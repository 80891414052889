<script>
import { required } from "vuelidate/lib/validators";
import { recordPayment } from "@/services/land.service";

export default {
  props: {
    paymentHistory: {
      type: Array,
      default: () => {
        return [];
      },
    },
    fetchingRecords: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    selectedPurchase: {
      type: Object,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      payment: {
        plan: "",
        amount: "",
        status: "",
        note: "",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "created_at",
      sortDesc: true,
      fields: [
        "#",
        {
          key: "created_at",
          label: "Recorded at",
          sortable: true,
          formatter: (value) => {
            return this.$options.filters.dateFormat(value);
          },
        },
        {
          key: "amount",
          sortable: true,
          label: "Amount Paid",
          formatter: (value) => {
            return this.$options.filters.currencyFormat(value);
          },
        },
        { key: "status", sortable: true, label: "Payment Status" },
      ],
      submitted: false,
      errors: {},
      loading: false,
    };
  },
  validations: {
    payment: {
      amount: { required, Number },
      status: { required },
    },
  },
  computed: {
    rows() {
      return this.paymentHistory.length;
    },
    isBusy() {
      return this.fetchingRecords;
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    totalPayment() {
      return this.paymentHistory.reduce(
        (sum, payment) => parseFloat(sum) + parseFloat(payment.amount),
        0
      );
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.paymentHistory.length;
    console.log(this.selectedPurchase)
  },
  methods: {
    async recordPayment() {
      this.$store.dispatch("notification/clear");
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if (
        !confirm(
          'Are you sure?\nYou cannot undo or delete a "confirmed" payment.'
        )
      ) {
        return;
      }

      try {
        this.errors = {};
        this.loading = true;
        this.payment.user_id = this.selectedPurchase.user_id;
        this.payment.estate_id = this.selectedPurchase.estate_id;
        this.payment.purchase_id = this.selectedPurchase.id;

        var response = await recordPayment(this.payment);

        this.$store.dispatch("notification/success", response.data.message);
        this.loading = false;
        this.payment.amount = "";
        this.payment.status = "";
        this.payment.note = "";
        this.submitted = false;

        this.$emit("addToPaymentHistory", response.data.data, this.payment.plan);
      } catch (error) {
        this.loading = false;
        console.log(error);
        switch (error.response.status) {
          case 422:
            this.$store.dispatch("notification/error", "An error occurred!");
            this.errors = error.response.data.errors;
            break;
          case 404:
            this.$store.dispatch("notification/error", "Resource not found!");
            break;
          case 500 || 401 || 403:
            this.$store.dispatch(
              "notification/error",
              error.response.data.message
            );
            break;
          default:
            this.$store.dispatch(
              "notification/error",
              "Something went wrong. Please try again!"
            );
            break;
        }
      }
      // return;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <b-card class="text-white-50" style="background: #4c0410">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">
                <p class="text-truncate font-size-16 mb-0 text-white">
                  Total Payments
                </p>
                <h2 class="mb-0 font-size-30 text-white">
                  {{ totalPayment | currencyFormat }}
                </h2>
                <p class="text-truncate font-size-14 mb-0" v-if="selectedPurchase.plan != null">
                  {{ selectedPurchase.plan }}
                </p>
              </div>
              <div class="text-primary">
                <i
                  class="ri-briefcase-4-line"
                  style="font-size: 40px; color: white"
                ></i>
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col-12" v-if="selectedPurchase.payment_status != 'completed'">
        <b-card style="background: white" class="text-black-50">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">
                <b-alert
                  :variant="notification.type"
                  class="mt-1"
                  v-if="notification.message"
                  show
                  dismissible
                  @dismissed="$store.dispatch('notification/clear')"
                  >{{ notification.message }}</b-alert
                >
                <h4 class="card-title mb-4">Add Payment</h4>
                <p
                  class="font-size-14 mb-0 text-black"
                  style="margin-left: 12px"
                >
                  Enter the amount without a comma (eg: 100000)
                </p>
                <div class="col-12">
                  <form @submit.prevent="recordPayment">
                    <div class="form-group row mb-3">
                      <div class="col-md-7">
                        <input
                          type="number"
                          class="form-control"
                          placeholder="Enter Amount Paid"
                          v-model="payment.amount"
                          :class="{
                            'is-invalid':
                              (submitted && $v.payment.amount.$error) ||
                              errors.amount,
                          }"
                        />
                        <div
                          v-if="
                            (submitted && $v.payment.amount.$error) ||
                            errors.amount
                          "
                          class="invalid-feedback"
                        >
                          <span v-if="errors.amount"
                            >{{ errors.amount[0] }}. &nbsp;</span
                          >
                          <span v-if="!$v.payment.amount.required"
                            >This field is required &nbsp;</span
                          >
                          <span v-if="!$v.payment.amount.Number"
                            >This field must be a number.</span
                          >
                        </div>
                      </div>

                      <div class="col-md-5">
                        <select
                          class="form-control"
                          placeholder=""
                          v-model="payment.status"
                          :class="{
                            'is-invalid':
                              (submitted && $v.payment.status.$error) || errors.status,
                          }"
                        >
                          <option value="" selected>
                            Select Payment Status
                          </option>
                          <option value="installment">Installment</option>
                          <option
                            value="completed"
                            class="bg-success text-white"
                          >
                            Completed
                          </option>
                        </select>
                        <div
                          v-if="(submitted && $v.payment.status.$error) || errors.status"
                          class="invalid-feedback"
                        >
                          <span v-if="errors.status"
                            >{{ errors.status[0] }}. &nbsp;</span
                          >
                          <span v-if="!$v.payment.status.required"
                            >Select a status</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="form-group row mb-3" v-if="selectedPurchase.estate.plans != null && selectedPurchase.plan == null">
                      <div class="col-md-12">
                        <select
                          class="form-control"
                          placeholder=""
                          v-model="payment.plan"
                          required
                        >
                          <option value="" selected>
                            Select Payment Plan
                          </option>
                          <option :value="plan.name + ' @' + $options.filters.currencyFormat(plan.price)" v-for="(plan, index) in selectedPurchase.estate.plans" :key="index">
                            {{ plan.name + ' @' + $options.filters.currencyFormat(plan.price) }}
                          </option>
                        </select>
                        <!-- <div
                          v-if="(submitted && $v.payment.status.$error) || errors.status"
                          class="invalid-feedback"
                        >
                          <span v-if="errors.status"
                            >{{ errors.status[0] }}. &nbsp;</span
                          >
                          <span v-if="!$v.payment.status.required"
                            >Select a status</span
                          >
                        </div> -->
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <div class="col-md-12">
                        <textarea
                          class="form-control"
                          placeholder="Note"
                          v-model="payment.note"
                        ></textarea>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <div class="col-md-12">
                        <button
                          class="col-md-12 btn btn-success"
                          :disabled="loading"
                        >
                          Add Payment
                          <b-spinner
                            v-show="loading"
                            small
                            class="align-middle"
                          ></b-spinner>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="card">
      <div class="card-body">
        <h4 class="card-title mb-4">Payment History</h4>
        <div class="row mt-4">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <div class="table-responsive">
          <b-table
            :items="paymentHistory"
            :fields="fields"
            responsive="sm"
            :busy="isBusy"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> &nbsp; Loading...</strong>
              </div>
            </template>
            <template #cell(#)="data">
              {{ data.index + 1 }}
            </template>
            <template v-slot:cell(status)="row">
              <div
                class="badge font-size-12"
                :class="{
                  'badge-soft-success': `${row.value}` === 'completed',
                  'badge-soft-warning': `${row.value}` === 'installment',
                }"
              >
                {{ row.value }}
              </div>
            </template>

            <!-- <template v-slot:cell(action)>
              <a
                href="javascript:void(0);"
                class="mr-3 text-primary"
                v-b-tooltip.hover
                data-toggle="tooltip"
                title="Edit"
              >
                <i class="mdi mdi-pencil font-size-18"></i>
              </a>
              <a
                href="javascript:void(0);"
                class="text-danger"
                v-b-tooltip.hover
                title="Delete"
              >
                <i class="mdi mdi-trash-can font-size-18"></i>
              </a>
            </template> -->
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>